<template>
  <el-dialog
    title="账号配置详情"
    :model-value="modelValue"
    width="600px"
    @close="handleClose"
  >
    <el-table :data="listData" border>
      <el-table-column prop="spa_account" label="账号" />
      <el-table-column prop="spa_password" label="密码" />
    </el-table>
    <template #footer>
      <span class="dialog-footer">
        <el-button type="primary" @click="handleClose" size="mini"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  listData: {
    type: Array,
    default: () => []
  }
})
const emits = defineEmits(['update:modelValue'])

const handleClose = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="scss" scoped></style>
