<template>
  <el-upload
    class="avatar-uploader"
    action=""
    :accept="accept"
    :show-file-list="false"
    :before-upload="beforeUpload"
    :on-remove="handleRemove"
    :http-request="doUpLoad"
  >
    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
    <div v-else class="avatar-uploader-icon">+</div>
  </el-upload>
  <el-progress v-show="progressFlag" :percentage="loadProgress" />
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { ElMessage } from 'element-plus'
import { getSts } from '@/api/oss'
import { client } from '@/utils/oss'

const props = defineProps({
  logoSrc: {
    type: String,
    default: ''
  },
  // 图片类型
  accept: {
    type: String,
    default: ''
  }
})

const emits = defineEmits(['update:logoSrc'])

const imageUrl = ref('')
const ossConfig = ref(null)
const progressFlag = ref(false)
const loadProgress = ref(0)

watch(
  () => props.logoSrc,
  (val) => {
    imageUrl.value = val
  },
  {
    immediate: true
  }
)

const beforeUpload = (rawFile) => {
  if (!props.accept.indexOf(rawFile.type) === -1) {
    ElMessage.error(`只支持 ${props.accept} 图片类型`)
    return false
  } else if (rawFile.size / 1024 / 1024 > 2) {
    ElMessage.error('图片最大支持 2M!')
    return false
  }
  // 这里是请求后台的签名接口
  return new Promise((resolve, reject) => {
    getSts().then(
      (res) => {
        ossConfig.value = res.data.data
        resolve(true)
      },
      (err) => {
        reject(err)
      }
    )
  })
}

// 执行上传到阿里云的动作
const doUpLoad = ({ file }) => {
  // 定义唯一的文件名
  const fileName = `${Date.parse(new Date())}/${file.name}`
  console.log(fileName, ossConfig.value)
  client(ossConfig.value)
    .multipartUpload(fileName, file, {
      async progress(p, cpt) {
        // p进度条的值
        progressFlag.value = true
        loadProgress.value = Math.floor(p * 100)
      }
    })
    .then(({ res }) => {
      progressFlag.value = false
      if (res && res.status === 200) {
        console.log('阿里云OSS上传文件成功回调', res)
        const url = res.requestUrls[0].split('?')[0]
        imageUrl.value = url
        emits('update:logoSrc', url)
      }
    })
    .catch((err) => {
      console.log('阿里云OSS上传失败回调', err)
    })
}

const handleRemove = () => {}
</script>

<style scoped>
.avatar-uploader .avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>

<style lang="scss">
.avatar-uploader .el-upload {
  border: 1px dashed #dcdfe6;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: 0.2s;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 150px;
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
