<template>
  <el-dialog
    :title="isEdit ? '编辑店铺' : '新增店铺'"
    :model-value="modelValue"
    @close="closed"
    width="600px"
  >
    <el-form
      class="create-form"
      ref="CreateFromRef"
      :model="createForm"
      :rules="createRules"
      label-width="120px"
      size="small"
    >
      <el-form-item label="店铺名称:" prop="name">
        <el-row :gutter="20">
          <el-col :span="20">
            <el-input
              v-model="createForm.name"
              placeholder="请输入"
              name="name"
              type="text"
            /> </el-col
        ></el-row>
      </el-form-item>
      <el-form-item
        v-for="(account, index) in createForm.add_account"
        :key="account.key"
        :label="index === 0 ? '账号配置:' : ''"
        style="margin-bottom: 0"
      >
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item
              :prop="'add_account.' + index + '.spa_account'"
              :rules="{
                required: true,
                trigger: 'blur',
                message: '请输入账号'
              }"
            >
              <el-input v-model="account.spa_account" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item
              :prop="'add_account.' + index + '.spa_password'"
              :rules="{
                required: true,
                trigger: 'blur',
                message: '请输入密码'
              }"
            >
              <el-input v-model="account.spa_password" placeholder="请输入" />
            </el-form-item>
          </el-col>
          <el-col :span="4">
            <el-button type="danger" @click.prevent="removeAccount(index)"
              >删除</el-button
            >
          </el-col>
        </el-row>
      </el-form-item>
      <el-form-item label="店铺logo:" prop="img">
        <Upload
          v-model:logoSrc="createForm.img"
          accept="image/jpg,image/jpeg,image/png"
        />
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="addAccount">新增账号配置</el-button>
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onConfirmClick" :loading="loading"
          >确定</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch } from 'vue'
import { addCompany, editCompany } from '@/api/company'
import { ElMessage } from 'element-plus'
import Upload from '@/components/Upload'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  isEdit: {
    type: Boolean,
    default: false
  },
  editInfo: {
    type: Object
  }
})

const emits = defineEmits(['update:modelValue', 'refresh'])

// 数据源
const loading = ref(false)
const deleteAccount = ref([])
const createForm = ref({
  name: '',
  add_account: [
    {
      key: Date.now(),
      spa_account: '',
      spa_password: ''
    }
  ],
  img: ''
})
// 验证规则
const createRules = ref({
  name: [
    {
      required: true,
      trigger: 'blur',
      message: '请输入店铺名称'
    }
  ]
})

const CreateFromRef = ref(null)

watch(
  () => props.modelValue,
  (val) => {
    if (val) {
      if (props.isEdit) {
        const formData = {}
        Object.keys(createForm.value).forEach((key) => {
          formData[key] =
            key === 'add_account'
              ? JSON.parse(JSON.stringify(props.editInfo.account))
              : props.editInfo[key]
        })
        createForm.value = formData
      }
    } else {
      CreateFromRef.value.resetFields()
      resetForm()
    }
  }
)

const addAccount = () => {
  createForm.value.add_account.push({
    key: Date.now(),
    spa_account: '',
    spa_password: ''
  })
}

const removeAccount = (index) => {
  if (createForm.value.add_account[index].id) {
    deleteAccount.value.push(createForm.value.add_account[index].id)
  }
  createForm.value.add_account.splice(index, 1)
}

/**
 * 关闭
 */
const closed = () => {
  emits('update:modelValue', false)
}

const resetForm = () => {
  deleteAccount.value = []
  createForm.value = {
    name: '',
    add_account: [
      {
        key: Date.now(),
        spa_account: '',
        spa_password: ''
      }
    ],
    img: ''
  }
}

/**
 * 触发表单验证
 */
const onConfirmClick = () => {
  CreateFromRef.value.validate((isValid) => {
    if (isValid) {
      props.isEdit ? postEdit() : postCreate()
    }
  })
}

/**
 * 提交新增
 */
const postCreate = async () => {
  loading.value = true
  try {
    await addCompany(createForm.value)
    ElMessage.success('新增店铺成功')
    emits('refresh')
    closed()
  } finally {
    loading.value = false
  }
}

/**
 * 提交修改
 */
const postEdit = async () => {
  loading.value = true
  try {
    const { add_account: account } = createForm.value
    const params = {
      ...createForm.value,
      company_id: props.editInfo.id,
      add_account: account.filter((item) => !item.id),
      update_account: account.filter((item) => item.id),
      delete_account: deleteAccount.value
    }
    console.log(params)
    await editCompany(params)
    ElMessage.success('修改店铺成功')
    emits('refresh')
    closed()
  } finally {
    loading.value = false
  }
}
</script>

<style lang="scss" scoped></style>
