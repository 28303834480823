const OSS = require('ali-oss')

export function client(data) {
  const regionReg = /https:\/\/(.*?)\./
  // 后端提供接口
  const region = data.end_point.match(regionReg)
    ? data.end_point.match(regionReg)[1]
    : 'oss-cn-beijing'
  return new OSS({
    region: region,
    accessKeyId: data.access_key_id,
    accessKeySecret: data.access_key_secret,
    stsToken: data.security_token,
    bucket: data.bucket
  })
}
