import request from '@/utils/request'

/**
 * 获取STS
 */
export const getSts = () => {
  return request({
    url: '/file/sts',
    method: 'GET'
  })
}
