<template>
  <div class="manage-container">
    <el-card class="search-header">
      <el-form
        :inline="true"
        ref="SearchFromRef"
        :model="searchForm"
        size="small"
      >
        <div class="search-form">
          <div>
            <el-form-item label="关键字搜索:" prop="keyword">
              <el-input
                v-model="searchForm.keyword"
                placeholder="请输入关键字"
              />
            </el-form-item>
          </div>
          <div>
            <el-form-item>
              <el-button @click="resetSearch">重置</el-button>
              <el-button type="primary" @click="getCompanyList">查询</el-button>
            </el-form-item>
          </div>
        </div>
      </el-form>
    </el-card>

    <el-card>
      <div class="table-header">
        <el-button
          type="primary"
          size="small"
          @click="openCreateModal"
          v-permission="['addShop']"
        >
          + 新增店铺
        </el-button>
      </div>
      <el-table
        :data="allCompanies"
        v-loading="loading"
        border
        style="width: 100%"
      >
        <el-table-column label="ID" prop="id"> </el-table-column>
        <el-table-column label="店名" prop="name"> </el-table-column>
        <el-table-column label="店铺logo" #default="{ row }">
          <el-image
            style="width: 50px; height: 50px"
            :src="row.img"
            v-if="row.img"
          />
          <span v-else>未上传</span>
        </el-table-column>
        <el-table-column label="配置账号" #default="{ row }">
          <span v-if="row.account.length === 0">暂无配置</span>
          <el-button
            v-else
            size="mini"
            type="text"
            @click="openAccountModal(row)"
            >查看</el-button
          >
        </el-table-column>
        <el-table-column
          v-permission="['addShop']"
          label="操作"
          prop="action"
          width="260"
          #default="{ row }"
        >
          <el-button type="primary" size="mini" @click="onEditClick(row)">
            编辑
          </el-button>
          <el-button type="danger" size="mini" @click="onRemoveClick(row)">
            删除
          </el-button>
        </el-table-column>
      </el-table>

      <el-pagination
        class="pagination"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-sizes="[5, 10, 50, 100, 200]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </el-card>

    <create-modal
      v-model="createModalVisible"
      @refresh="getCompanyList"
      :is-edit="isEdit"
      :edit-info="editInfo"
    />

    <account-modal v-model="accountModalVisible" :list-data="accountList" />
  </div>
</template>

<script setup>
import { ref, reactive, onActivated } from 'vue'
import { ElMessageBox, ElMessage } from 'element-plus'
import { companyList, deleteCompany } from '@/api/company'
import CreateModal from './components/CreateModal.vue'
import AccountModal from './components/AccountModal.vue'

// 数据相关
const allCompanies = ref([])
const total = ref(0)
const page = ref(1)
const size = ref(10)

// 获取数据的方法
const loading = ref(false)
const getCompanyList = async () => {
  loading.value = true
  const result = await companyList({
    page: page.value,
    size: size.value,
    keyword: searchForm.keyword !== '' ? searchForm.keyword : undefined
  })
  loading.value = false
  allCompanies.value = result.data.list
  total.value = result.data.total || 0
}

// 处理数据不重新加载的问题
onActivated(getCompanyList)

// 搜索相关
const SearchFromRef = ref(null)
const searchForm = reactive({
  keyword: ''
})

// 重置搜索
const resetSearch = () => {
  SearchFromRef.value.resetFields()
  getCompanyList()
}

/**
 * size 改变触发
 */
const handleSizeChange = (currentSize) => {
  size.value = currentSize
  getCompanyList()
}

/**
 * 页码改变触发
 */
const handleCurrentChange = (currentPage) => {
  page.value = currentPage
  getCompanyList()
}

// 编辑店铺
const editInfo = ref(null)
const isEdit = ref(false)
const onEditClick = (row) => {
  editInfo.value = row
  isEdit.value = true
  createModalVisible.value = true
}

// 删除店铺
const onRemoveClick = (row) => {
  ElMessageBox.confirm(`确定要删除【${row.name}】店铺吗？`, {
    type: 'warning'
  }).then(async () => {
    await deleteCompany(row.id)
    ElMessage.success('删除成功')
    // 重新渲染数据
    getCompanyList()
  })
}

// 打开新增店铺弹窗
const createModalVisible = ref(false)
const openCreateModal = () => {
  editInfo.value = null
  isEdit.value = false
  createModalVisible.value = true
}

// 查看配置账号
const accountModalVisible = ref(false)
const accountList = ref([])
const openAccountModal = (item) => {
  if (item.account.length === 0) {
    return
  }
  accountList.value = item.account
  accountModalVisible.value = true
}
</script>

<style lang="scss" scoped>
.manage-container {
  .search-header {
    margin-bottom: 20px;

    ::v-deep .el-form-item--small.el-form-item {
      margin-bottom: 0;
    }
    .search-form {
      display: flex;
      justify-content: space-between;
    }
  }

  .table-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }

  ::v-deep .el-table__row {
    cursor: pointer;
  }

  .pagination {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
